.tablist {
  position: relative;
  display: flex;
  align-items: center;
  .tabs {
    display: flex;
    align-items: center;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    width: 100%;
    .tab {
      // position: relative;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      margin: 0 0.5rem 0 0;
      padding: 0.75rem 1rem;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      display: inline;
      font-weight: bold;
      font-size: 1rem;
      .activity {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 10px;
      }
      .close {
        position: absolute;
        top: 0;
        right: 0.25rem;
        display: flex;
        align-items: center;
        height: 100%;
      }
      &.tabClose {
        padding-right: 2.5rem;
      }
    }
  }
  .controls {
    margin-left: auto;
  }
}
