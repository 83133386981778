.image {
  display: inline-block;
  position: relative;
  overflow: hidden;
  img,
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: auto;
  }
  &.small {
    $my-size: 2rem;
    height: $my-size;
    width: $my-size;
    min-height: $my-size;
    max-height: $my-size;
    max-width: $my-size;
    border-radius: 0.5rem;
    &.round {
      border-radius: $my-size;
    }
    img {
      width: $my-size;
    }
  }
  &.medium {
    $my-size: 3rem;
    height: $my-size;
    width: $my-size;
    min-height: $my-size;
    max-height: $my-size;
    max-width: $my-size;
    &.round {
      border-radius: $my-size;
    }
    img {
      width: $my-size;
    }
  }
  &.large {
    $my-size: 10rem;
    height: $my-size;
    width: $my-size;
    min-height: $my-size;
    max-height: $my-size;
    max-width: $my-size;
    &.round {
      border-radius: $my-size;
    }
    img {
      width: $my-size;
    }
  }
}
