.button {
  border: solid 1px var(--fo-alt-yellow);
  background-color: var(--fo-frame-overlay-greater);
  color: var(--fo-alt-yellow);
  &:hover {
    border: solid 1px red;
    // background-color: var(--fo-alt-yellow);
    background-color: #900;
    box-shadow: inset 0 0 16px 4px red;
    > div {
      color: white;
    }
    transform: scale(1.05);
    transition-duration: 300ms;
    box-shadow: 3px 3px 3px black;
    &:active {
      box-shadow: 3px 3px 3px black;
      transform: scale(1.005);
    }
  }
}
