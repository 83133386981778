.avail {
  width: 20rem;

  @media screen and (max-width: 60rem) {
    width: 100%;
  }
}
.line {
  stroke: var(--fo-alt-yellow);
  stroke-width: 0.5pt;
}
.now {
  stroke: var(--fo-text-white);
  stroke-width: 1pt;
}
.text {
  font: normal 8px sans-serif;
  fill: var(--fo-alt-yellow);
}
.box {
  fill: #f2c45e88;
  &.off {
    fill: transparent;
  }
  &.viewer {
    &:hover {
      fill: var(--fo-alt-yellow);
    }
    &:active {
      fill: white;
    }
  }
}
.focusedx {
  fill: var(--fo-alt-yellow);
}
