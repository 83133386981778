.bgWrapper {
  .background {
    @media (orientation: portrait) {
      height: 100vh;
      width: auto;
    }
    @media (orientation: landscape) {
      height: auto;
      width: 100vw;
    }
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-height: inherit;
    background-position: center center;
    background-size: cover;
    margin: 0;
    @media screen and (min-width: 30em) {
      &.imgPulse {
        animation: grow-shrink 60s ease-in-out infinite;
      }
    }
  }
  overflow: hidden;
}
.bgContent {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
  .bgCredit {
    position: fixed;
    padding: 0.25rem;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.5rem;
    color: rgba(255, 255, 255, 0.5);
    .embiggen:hover {
      font-size: 1rem;
      color: white;
      transition: all ease-in-out 0.25s;
    }
  }
}
// Future investigation.  http://css3.bradshawenterprises.com/cfimg/
// consider using img tags at a low layer
@keyframes grow-shrink {
  0% {
    transform: perspective(1000px) translate3d(10px, 0, 75px);
  }
  50% {
    transform: perspective(1000px) translate3d(0, 0, 0);
  }
  100% {
    transform: perspective(1000px) translate3d(10px, 0, 75px);
  }
  /*
  0%{ transform: scale(1.2) }
  50%{ transform: scale(1) }
  100%{ transform: scale(1.2) }
  */
}
