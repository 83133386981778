.scroll {
  height: 100vh;
  max-height: 100vh;
}
.grid {
  position: relative;
  height: 100%;
  display: grid;
  margin: 0;

  grid-template-areas:
    'brand'
    'menus'
    'announce'
    'codes';
  .brand {
    width: 100vw;
    grid-area: brand;
    height: fit-content;
  }
  .menus {
    width: 100vw;
    // border: solid 1px red;
    grid-area: menus;
    align-self: start;
    .opt {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      display: block;
      color: var(--fo-text-white);
      &:hover {
        background-color: var(--fo-text-white) !important;
        color: black !important;
        text-shadow: none !important;
      }
      &:active {
        background-color: var(--fo-alt-yellow);
      }
    }
    .optSm {
      font-weight: 400;
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
    }
    button,
    a {
      border: none;
    }
    .profileMenu {
      text-align: left;
      > div,
      > a {
        text-align: left;
        flex-direction: row-reverse;
        justify-content: flex-end;
        i {
          padding-left: 0;
          padding-right: 0.5rem;
        }
      }
    }
  }
  .announce {
    width: 100vw;
    grid-area: announce;
    display: flex;
    justify-content: flex-end;
    .announceBox {
      height: fit-content;
      ul {
        list-style: none;
        padding: 0;
        padding-left: 1.2rem;
        li {
          a:hover {
            color: var(--fo-alt-yellow-hilite);
          }
          margin-bottom: 0.5rem;
        }
        li:before {
          // content: '\f067';
          // content: '\f00c';
          content: '\f061';
          font-size: 0.75rem;
          font-weight: 900;
          font-family: 'Font Awesome 5 Free';
          margin-left: -1.5em;
          padding-right: 0.4rem;
          width: 1.5rem;
        }
      }
      @media screen and (min-width: 30rem) {
        width: 75%;
      }
    }
  }
  .codes {
    width: 100vw;
    margin-top: 3rem;
    grid-area: codes;
  }

  @media screen and (min-width: 30rem) {
    grid-template-areas:
      'brand announce'
      'menus announce'
      'codes codes';
    .brand {
      width: auto;
      grid-area: brand;
      height: fit-content;
    }
    .menus {
      width: auto;
      grid-area: menus;
      align-self: start;
      margin-left: 2rem;
    }
    .announce {
      width: auto;
      grid-area: announce;
      display: flex;
      justify-content: flex-end;
    }
    .codes {
      width: auto;
      margin-top: 3rem;
      grid-area: codes;
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }
}
//  @media screen and (max-width: 28rem) {
//    .codes,
//    .back,
//    .summary,
//    .news,
//    .top {
//      margin-bottom: 1rem;
//    }
//  }
//  @media screen and (min-width: 28rem) {
//    //outline: solid 1px red;
//    margin-left: 1rem;
//    margin-right: 0.5rem;
//  }
//  // @media screen and (min-width: 28rem) and (max-width: 45rem) {
//  //   //outline: solid 1px white;
//  //   grid-template-areas:
//  //     'links  links  codes'
//  //     'back   back   codes'
//  //     'back   back   summary'
//  //     'top    news   news'
//  //     'market market market';
//  //   .codes,
//  //   .summary,
//  //   .news,
//  //   .top {
//  //     margin-bottom: 1rem;
//  //   }
//  //   .news {
//  //     margin-left: 1rem;
//  //   }
//  // }
//  @media screen and (min-width: 45rem) and (max-width: 60rem) {
//    //outline: solid 1px green;
//    grid-template-areas:
//      'links  links  codes'
//      'back   back   codes'
//      'back   back   summary'
//      'top    news   news'
//      'market market market';
//    .news {
//      margin-left: 1rem;
//    }
//    .summary,
//    .codes,
//    .top {
//      margin-bottom: 1rem;
//    }
//  }
//  @media screen and (min-width: 60rem) {
//    //outline: solid 1px blue;
//    grid-template-areas:
//      'links links links top'
//      'back  back  back top'
//      'codes codes codes top'
//      'market market news news'
//      'market market summary summary';
//    .top,
//    .codes,
//    .summary,
//    .right {
//      margin-bottom: 1rem;
//    }
//    .codes,
//    .market {
//      margin-right: 1rem;
//    }
//  }
//}
//a.altButton {
//  border-radius: .5rem;
//  border: solid 2px var(--t-accent);
//  background-color: var(--fo-frame-gray);
//  color: var(--fo-text-white);
//  &:hover {
//    background-color: var(--fo-frame-gray2);
//    color: white;
//  }
