.navbar {
  border: none;
  background-color: var(--fo-frame-overlay-greater);
  backdrop-filter: blur(10px);
  color: var(--fo-text-white);
  padding: 2px;
  width: 100%;
}
.navlink {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  border: solid 1px transparent;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  font-weight: bold;
  background-color: transparent !important;
  border-radius: 0;
  outline: 0;
  color: var(--fo-text-white);
  display: block;
  &.menuitem {
    text-align: left;
  }
  &:visited {
    color: var(--fo-text-white);
  }
  &:hover {
    border: solid 1px var(--fo-alt-yellow);
  }
  &:after {
    content: '';
    clear: both;
  }
  &.active {
    border: solid 1px var(--fo-alt-yellow);
    background-color: var(--fo-alt-yellow) !important;
    color: #000;
    &:hover {
      background-color: var(--fo-alt-yellow-hilite) !important;
      color: #000;
    }
  }
  &.activeImg {
    border: solid 1px var(--fo-alt-yellow);
    color: #000;
    &:hover {
      color: #000;
    }
  }
}
