.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
}
.scroll {
  height: calc(100vh - 10rem) !important;
  max-height: calc(100vh - 10rem);
}
.modal {
  .frame {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    &.fw-50 {
      @media screen and (min-width: 30rem) {
        top: 4rem;
        left: 15%;
        right: 15%;
      }
      @media screen and (min-width: 60rem) {
        top: 6rem;
        left: 25%;
        right: 25%;
      }
      @media screen and (orientation: landscape) and (min-width: 30rem) {
        top: 2rem;
      }
      @media screen and (orientation: portrait) and (min-width: 60rem) {
        top: 2rem;
      }
    }
    &.fw-75 {
      @media screen and (min-width: 30rem) {
        left: 5%;
        right: 5%;
      }
      @media screen and (min-width: 60rem) {
        left: 12%;
        right: 12%;
      }
    }
    &.fw-95 {
      @media (min-width: 60rem) {
        // @media screen and (min-width: var(90rem)) {
        margin-left: auto;
        margin-right: auto;
        max-width: 55rem;
      }

      @media screen and (min-width: 30rem) {
        left: 1rem;
        right: 1rem;
      }
      @media screen and (min-width: 60rem) {
        left: 2rem;
        right: 2rem;
      }
    }
    &.fw-min {
      @extend .fw-50;
      top: 25%;
      bottom: auto;
    }
    &.fw-shrink {
      @extend .fw-50;
      top: 25%;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      bottom: auto;
    }
    &.fw-max {
      left: 1rem;
      right: 1rem;
      @media (min-width: 60rem) {
        // @media screen and (min-width: var(90rem)) {
        margin-left: auto;
        margin-right: auto;
        max-width: 55rem;
      }
    }
    // box-shadow: 5px 5px 15px var(--t-gray);
    // border: solid 2px var(--t-solidbg-dark);
    // background-color: var(--t-gray);
    // backdrop-filter: blur(10px);
    // color: var(--t-primary);
    .body {
      // padding-left: 0.5rem;
      padding: 0;
    }
    .close {
      cursor: pointer;
      position: absolute;
      height: calc(1.25rem - 1px);
      width: calc(1.25rem - 1px);
      top: 0.5rem;
      right: 0;
      @media screen and (min-width: 30rem) {
        right: 0.5rem;
      }
    }
  }
}
