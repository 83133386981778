@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

:root {
  --p-br-over: 5px;
  --p-bg-over-light: rgba(255, 255, 255, 0.25);
  --p-bg-over-dark: rgba(0, 0, 0, 0.5);
  --p-fg-over-light: rgba(255, 255, 255, 0.5);
  --p-fg-over-dark: rgba(0, 0, 0, 0.5);
  --p-fg-light: #222;
  --p-radius: 0.25rem;
  --p-radius-m: 0.5rem;
  --p-radius-l: 1rem;
  /* assume small as default */
  --p-md: 30em;
  --p-lg: 60em;
  --p-c-none: transparent;
  --p-c-work: rgb(25, 169, 116);
  --p-c-personal: rgb(53, 126, 221);
  --p-c-unmapped: rgb(255, 65, 54);
  --p-c-conflict: rgb(255, 65, 180);
  --t-accent: #1e47ba;
  --tc-red: #ff4136;
  --fo-frame-overlay: rgba(7, 16, 21, 0.7);
  --fo-frame-overlay-greater: rgba(7, 16, 21, 0.85);
  --fo-frame-overlay-lesser: rgba(7, 16, 21, 0.25);
  --fo-lighten-overlay: rgba(242, 196, 94, 0.2);
  --fo-frame-gray: #1a2028;
  --fo-frame-gray2: #1f262f;
  --fo-frame-text: #111;
  --fo-primary-gray: #111413;
  --fo-primary-gray-hover: #262d2b;
  --fo-primary-gray2: #394247;
  --fo-primarybg-text: #fbfdd6;
  --fo-alt-yellow: #f2c45e;
  --fo-alt-yellow-hilite: #edcc80; // #F2D492;
  --fo-alt-yellow-hilite2: #f2e8d2;
  --fo-altbg-text: #000;
  --fo-text-white: #fbfdd6;
}
/* these are from tachyons */
@custom-media --breakpoint-not-small screen and (min-width: 30em);
@custom-media --breakpoint-medium screen and (min-width: 30em) and (max-width: 60em);
@custom-media --breakpoint-large screen and (min-width: 60em);

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

body {
  background-color: black;
  color: var(--fo-text-white);
  // overflow: hidden;
  overflow-y: scroll;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: 500;
  // height: 100vh;
  // max-height: 100vh;
  display: flex;
  margin: 0;
  padding: 0;
  /*border: dotted 2px green; /*debug*/
}

.noscroll {
  overscroll-behavior: none none;
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;
}

.hover-outline {
  border: solid 1px transparent;
}
.hover-outline:hover {
  border: solid 1px var(--fo-alt-yellow);
}
.hover-lighten:hover {
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(10px);
}
.hover-lighten5:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(10px);
}
.hover-lighten2:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(10px);
}
.hover-lighten1:hover {
  background-color: rgba(255, 255, 255, 0.005) !important;
  backdrop-filter: blur(10px);
}
.hover-unhide {
  color: transparent;
}
.hover-lighten:hover .hover-unhide {
  color: gray;
}
.hover-fade:hover {
  background-color: rgba(100, 100, 100, 0.5) !important;
}
.hover-darken:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  backdrop-filter: blur(10px);
}
.darken1 {
  background-color: rgba(0, 0, 0, 0.1);
}
.hover-outline1:hover {
  border: solid 2px gray;
}

.hover-outline1 {
  border: solid 2px transparent;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
}
#root {
  width: 100%;
  height: inherit;
  max-height: inherit;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.card-red,
.card-green,
.card-blue {
  color: white;
}
.card-unknown {
  color: dark;
}
.card-red {
  background-color: rgba(200, 0, 0, 0.6) !important;
}
.card-green {
  background-color: rgba(0, 150, 0, 0.6) !important;
}
.card-blue {
  background-color: rgba(0, 0, 200, 0.5) !important;
}
.card-unknown {
  background-color: inherit;
}
a:visited,
a {
  text-decoration: none;
  color: var(--fo-alt-yellow);
}
.br-over {
  border-radius: var(--p-br-over) var(--p-br-over) var(--p-br-over)
    var(--p-br-over);
}
/* common buttons */
.button {
  border-radius: 0.5rem;
  text-align: center;
}
.button-clear-light,
.button-clear-dark {
  background-color: transparent;
}
.button-clear-light {
  color: var(--fo-alt-yellow);
  &:hover:not(.disabled) {
    color: black;
    background-color: var(--fo-alt-yellow);
  }
}
.button-clear-dark {
  color: black;
  &:hover:not(.disabled) {
    background-color: var(--fo-frame-overlay);
  }
}

.button {
  background: var(--fo-alt-yellow);
  color: var(--fo-altbg-text);
}
.button.disabled,
.button-clear-dark.disabled,
.button-clear-light.disabled {
  color: gray;
}
.button:hover:not(.disabled) {
  background: var(--fo-alt-yellow-hilite); // #B4885C; // #77f;
}
div.square {
  position: relative;
  /* width: 100px;  specify width in HTML */
}
div.square:before {
  content: '';
  display: block;
  padding-top: 100%; /* ratio of 1:1*/
}
div.square div.square-guts {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.editing {
  background-color: white;
  color: black;
  padding: 0.25rem;
}

.hide-small {
  display: none !important;
  visibility: hidden;
}

.hide-medium {
  display: none !important;
  visibility: hidden;
}

@media screen and (min-width: 30em) {
  .hide-small {
    display: inherit !important;
    visibility: inherit;
  }
}

@media screen and (min-width: 60em) {
  .hide-small {
    display: inherit !important;
    visibility: inherit;
  }
  .hide-medium {
    display: inherit !important;
    visibility: inherit;
  }
}

/* PITA - this should work at the module level; putting here for now */

.loading-inline {
  margin-left: 1em;
  width: 1em;
  height: 1em;
  border-radius: 3em;
  animation: spin 1s linear infinite;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #ffffff;
}

/* loading spinner */
.loading-overlay {
  z-index: 2147483647;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.loading,
.loading-body {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 60vw;
  height: 60vw;
  margin: -30vw 0 0 -30vw;
  @media screen and (min-width: 30em) {
    width: 30vw;
    height: 30vw;
    margin: -15vw 0 0 -15vw;
  }
  @media screen and (min-width: 60em) {
    width: 10vw;
    height: 10vw;
    margin: -5vw 0 0 -5vw;
  }
}
.loading-body {
  text-align: center;
  top: 45%;
}
.loading {
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: var(--fo-alt-yellow);
  -webkit-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
.loading:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: var(--fo-alt-yellow-hilite);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loading:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: var(--fo-alt-yellow-hilite2);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-leave {
  opacity: 1;
}
.loading-leave.loading-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* hr with text in the middle -- hrstrike */
.hrstrike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.hrstrike > div {
  position: relative;
  display: inline-block;
}
.hrstrike > div:before,
.hrstrike > div:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #999;
}
.hrstrike > div:before {
  right: 100%;
  margin-right: 15px;
}
.hrstrike > div:after {
  left: 100%;
  margin-left: 15px;
}

/* END of background image */
ul.arrow {
  list-style: none;
  li:before {
    //    content: '\1F95E';
    //    content: '\12665';
    //   content: '\u{25bb}';
    content: '▻ ';
    //   content: '&#x25BB';
    //  margin-left: -20px;
    //  margin-right: 10px;
  }
}

input {
  background-color: #000;
  color: #ddd;
}
.fo-frame {
  background-color: var(--fo-frame-overlay);
  backdrop-filter: blur(10px);
  color: var(--fo-text-white);
  input,
  .input,
  textarea,
  .textarea {
    background-color: var(--fo-frame-overlay);
    color: var(--fo-text-white);
    border: none;
    padding: 0.5rem;
    outline: 0;
    border-radius: 0;
    &.outline {
      border: solid 1px var(--fo-frame-gray2);
    }
    &:focus {
      outline: 1px solid var(--fo-alt-yellow);
    }
    &::placeholder {
      color: var(--fo-primary-gray2);
      font-style: italic;
    }
    &.noedit {
      background-color: var(--fo-frame-overlay-lesser);
    }
  }

  label {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--fo-alt-yellow);
    text-transform: uppercase;
  }
}
.fo-frame-greater {
  background-color: var(--fo-frame-overlay-greater);
  backdrop-filter: blur(10px);
  color: var(--fo-text-white);
}
.fo-frame-lesser {
  background-color: var(--fo-frame-overlay-lesser);
  backdrop-filter: blur(10px);
  color: var(--fo-text-white);
}
.fo-frame-hover:hover,
.fo-frame-hover:hover a {
  background-color: var(--fo-alt-yellow-hilite);
  color: black;
}
button,
.button {
  cursor: pointer;
  border: solid 1px transparent;
  color: var(--fo-primary-gray);
  background-color: var(--fo-alt-yellow);
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-weight: bold;
  white-space: nowrap;
  &:visited {
    color: var(--fo-primary-gray);
  }
  &:hover {
    background-color: var(--fo-alt-yellow-hilite);
  }
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  &:active {
    background-color: var(--fo-alt-yellow-hilite2);
  }
  &.invert {
    background-color: var(--fo-primary-gray);
    color: var(--fo-alt-yellow);
    font-weight: normal;
    &:hover {
      border: solid 1px var(--fo-alt-yellow);
    }
    &:active {
      background-color: var(--fo-primary-gray-hover);
    }
  }
}
.fo {
  &::placeholder,
  ::placeholder {
    color: var(--fo-text-white);
    font-style: italic;
  }
}
.bg-theme-accent {
  background-color: var(--t-accent);
}
.fg-theme-accent {
  color: var(--t-accent);
}
.b--theme-accent {
  border-color: var(--t-accent);
}
.bg-fo-white {
  background-color: var(--fo-text-white);
  color: var(--fo-primary-gray);
}
.bg-fo-frame-overlay {
  background-color: var(--fo-frame-overlay);
}
.bg-fo-frame-overlay-greater {
  background-color: var(--fo-frame-overlay-greater);
}
.bg-fo-frame-overlay-lesser {
  background-color: var(--fo-frame-overlay-lesser);
}
.b--fo-frame-gray {
  border-color: var(--fo-frame-gray);
}
.fo-frame-gray {
  color: var(--fo-frame-gray);
}
.fo-frame-row {
  background-color: var(--fo-frame-overlay-lesser);
}
.bg-fo-frame-gray2 {
  background-color: var(--fo-frame-gray2);
}
.bg-fo-frame-gray {
  background-color: var(--fo-frame-gray);
}
.bg-fo-alt-yellow {
  background-color: var(--fo-alt-yellow);
  color: black;
}
.fo-alt-yellow {
  color: var(--fo-alt-yellow);
}
.fo-text-white {
  color: var(--fo-text-white);
}
.b--fo-alt-yellow {
  border-color: var(--fo-alt-yellow);
}
// --fo-frame-text: #111;
// --fo-primary-gray: #111413;
// --fo-primary-gray-hover: #262D2B;
// --fo-primary-gray2: #394247;
// --fo-primarybg-text: #FBFDD6;
// --fo-alt-yellow-hilite: #F2D492;
// --fo-alt-yellow-hilite2: #F2E8D2;
// --fo-altbg-text: #000;

.tabs {
  background-color: var(--fo-frame-overlay-greater);
  backdrop-filter: blur(10px);
  padding: 2px;
  // width: 100%;

  .tab,
  button.tab {
    text-align: center;
    border: solid 1px transparent;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    font-weight: bold;
    outline: 0;
    text-transform: uppercase;
    color: var(--fo-text-white);
    a {
      color: var(--fo-text-white);
    }
    background-color: transparent;
    border-radius: none;
    &:visited {
      color: var(--fo-text-white);
    }
    &:hover {
      border: solid 1px var(--fo-alt-yellow);
    }
    &.active {
      // border: solid 2px var(--fo-primary-gray);
      border: solid 1px var(--fo-alt-yellow);
      background-color: var(--fo-alt-yellow);
      color: #000;
      &:hover {
        background-color: var(--fo-alt-yellow-hilite);
        color: #000;
      }
    }
  }

  .tab5 {
    @media screen and (min-width: 30em) {
      width: 20%;
    }
  }
}

// scroll0 - ... not sure what the diff is
// scroll0h - all (no pad) except header/NavBar
// scroll1 - the entirety of the first level inner box
// scroll1h - first level box, except a top level header
// scroll2 - entirety of the second tier box
// scroll2h - second tier box except header
.scroller,
.scroll,
.scroll0,
.scroll0h,
.scroll1,
.scroll1h,
.scroll2,
.scroll2h {
  overflow-y: auto;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    width: 0.5rem;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.5rem;
  }
}

.scroll0 {
  height: calc(100vh - 1.7rem);
  max-height: calc(100vh - 1.7rem);
}

.scroll0h {
  height: calc(100vh - 1.75rem);
  max-height: calc(100vh - 1.75rem);
  /*
  @media screen and (min-width: 30em) {
    height: calc(100vh - 5.70rem);
    max-height: calc(100vh - 5.70rem);
  }
  */
}

.scroll1 {
}
.scroll1h {
  height: calc(100vh - 1.7rem);
  max-height: calc(100vh - 1.7rem);
  @media screen and (min-width: 30em) and (max-width: 60em) {
    height: calc(100vh - 6.7rem);
    min-height: calc(100vh - 6.7rem);
  }
  @media screen and (min-width: 60em) {
    height: calc(100vh - 8.5rem);
    min-height: calc(100vh - 8.5rem);
  }
}
.scroll2 {
  height: calc(100vh - 3.8rem);
  max-height: calc(100vh - 3.8rem);
  @media screen and (min-width: 30em) and (max-width: 60em) {
    height: calc(100vh - 6.7rem);
    max-height: calc(100vh - 6.7rem);
  }
  @media screen and (min-width: 60em) {
    height: calc(100vh - 8.5rem);
    max-height: calc(100vh - 8.5rem);
  }
}
.scroll2h {
  height: calc(100vh - 6.5rem);
  max-height: calc(100vh - 6.5rem);
  @media screen and (min-width: 30em) and (max-width: 60em) {
    height: calc(100vh - 9.5rem);
    max-height: calc(100vh - 9.5rem);
  }
  @media screen and (min-width: 60em) {
    height: calc(100vh - 11.25rem);
    max-height: calc(100vh - 11.25rem);
  }
}

.flex-center,
.cbrand,
.cicon {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}
.cbrand,
.cicon {
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  max-height: 1.5rem;
  border-radius: 2rem;
}
.cbrand {
  font-weight: bold;
  background: var(--fo-alt-yellow);
  font-size: 0.65rem;
  color: #000;
}

.transparent {
  color: transparent;
}

.input-over-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.25);
  border-radius: 2rem;
  &:hover {
    color: black;
    background: rgba(255, 255, 255, 0.5);
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  td,
  th {
    padding: 0.125rem 0.25rem 0.125rem 0.25rem;
  }
  th {
    color: var(--fo-alt-yellow);
    font-weight: bold;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  &.rowHover tr:hover {
    background: var(--fo-alt-yellow);
    color: black;
  }
  &.rowPointer tr {
    cursor: pointer;
  }
}

table.altrows tr:nth-child(even) > td,
table.altrows tr:nth-child(even) > th {
  background-color: rgba(255, 255, 255, 0.1);
}

.docstyle {
  li,
  dd,
  p {
    margin-top: 0.5rem;
  }
  dt,
  .uctitle,
  .title {
    margin-top: 2rem;
    color: var(--fo-alt-yellow);
  }
  .uctitle,
  .uc {
    text-transform: uppercase;
  }
}

.mr-dafoe {
  font-family: 'Mr Dafoe', Avenir;
  font-weight: normal;
}
.roboto-black {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.text-outline {
  // have it hang a little
  text-shadow: 2px 2px 3px black, -1px -1px 2px black, -1px 1px 0 black,
    1px 1px 0 black, 1px -1px 0 black;
}
.drop-shadow {
  filter: drop-shadow(2px 2px 3px black)
    drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}
.box-shadow {
  box-shadow: 3px 3px 3px black;
}

// TODO: import tachyons and change the large breakpoint to be 70rem
.max-view-page {
  @media (min-width: 60rem) {
    // @media screen and (min-width: var(90rem)) {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
  }
}
